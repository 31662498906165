// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---atacama-packages-packages-themes-theme-default-src-pages-404-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/404.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-404-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/contact.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/thanks.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js" */),
  "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx" */),
  "component---atacama-packages-packages-web-core-src-pages-info-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/---info.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-info-js" */),
  "component---atacama-packages-packages-web-core-src-templates-page-legal-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-templates-page-legal-js" */),
  "component---src-pages-bauklempnerei-zimmerei-js": () => import("./../../../src/pages/bauklempnerei-zimmerei.js" /* webpackChunkName: "component---src-pages-bauklempnerei-zimmerei-js" */),
  "component---src-pages-bedachungen-js": () => import("./../../../src/pages/bedachungen.js" /* webpackChunkName: "component---src-pages-bedachungen-js" */),
  "component---src-pages-fassade-js": () => import("./../../../src/pages/fassade.js" /* webpackChunkName: "component---src-pages-fassade-js" */),
  "component---src-pages-garage-js": () => import("./../../../src/pages/garage.js" /* webpackChunkName: "component---src-pages-garage-js" */),
  "component---src-pages-hausdach-1-js": () => import("./../../../src/pages/hausdach-1.js" /* webpackChunkName: "component---src-pages-hausdach-1-js" */),
  "component---src-pages-hausdach-2-js": () => import("./../../../src/pages/hausdach-2.js" /* webpackChunkName: "component---src-pages-hausdach-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isolierungen-js": () => import("./../../../src/pages/isolierungen.js" /* webpackChunkName: "component---src-pages-isolierungen-js" */),
  "component---src-pages-schieferarbeiten-js": () => import("./../../../src/pages/schieferarbeiten.js" /* webpackChunkName: "component---src-pages-schieferarbeiten-js" */),
  "component---src-pages-schmiedehalle-js": () => import("./../../../src/pages/schmiedehalle.js" /* webpackChunkName: "component---src-pages-schmiedehalle-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */)
}

